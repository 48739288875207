import { pic } from './global'
//波特率选项常量
export const baudrate = [
    { key: 115200, value: 115200 },
    { key: 57600, value: 57600 },
    { key: 38400, value: 38400 },
    { key: 19200, value: 19200 },
    { key: 14400, value: 14400 },
    { key: 9600, value: 9600 },
    { key: 4800, value: 4800 },
    { key: 2400, value: 2400 }
]

//数据属性选项常量
export const dataAttr = [
    { key: 0, value: 'Signed（默认）' },
    { key: 1, value: 'Unsigned' },
    { key: 2, value: '32_Signed_Le' },
    { key: 3, value: '32_Signed_Le_Swap' },
    { key: 4, value: '32_Signed_Be' },
    { key: 5, value: '32_Signed_Be_Swap' },
    { key: 6, value: '32_Float_Le' },
    { key: 7, value: '32_Float_Le_Swap' },
    { key: 8, value: '32_Float_Be' },
    { key: 9, value: '32_Float_Be_Swap' },
]

//校验位选项常量
export const parity = [
    { key: 'None', value: '无校验' },
    { key: 'Even', value: '偶校验' },
    { key: 'Odd', value: '奇校验' }
]

//方案图标
export const planImg = [
    { key: 'blue.png', value: 'blue.png' },
    { key: 'leaf.png', value: 'leaf.png' },
]

//数据位选项常量
export const dataBits = [
    { key: 8, value: 8 }
]

//日期格式
export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

//停止位选项常量
export const stopBits = [
    { key: 1, value: 1 }
]

//oss前缀
export const OSS_URL = pic

//系统图片
export const SYSTEM_IMG = 'https://oss.cniot.fun/iot-system/sys-program/'

export const ALARM_IMG = 'alarm/';
export const GATEWAY_IMG = 'gateway/';
export const INSPECTION_IMG = 'inspection/';
export const NOTICE_IMG = 'notice/';
export const ORIGINAL_IMG = 'original/';
export const PLAN_IMG = 'plan/';
export const PROJECT_IMG = 'project/';
export const PLATFORM_IMG = 'platform/';
export const wechatimg_IMG = 'wechatimg/';
export const SOLUTION_IMG = 'solution/';
export const CONFIGURATION_IMG = 'configuration/';

// 文档菜单类型
export const workTypeBtn = [
    { key: 0, value: '菜单' },
    { key: 1, value: '视频菜单' },
    { key: 2, value: '视频' },
]
// 菜单类型
export const menuType = [
    { key: 0, value: '菜单' },
    { key: 1, value: '服务' },
    { key: 2, value: '按钮' },
]
// 菜单状态
export const menuState = [
    { key: 0, value: '开通' },
    { key: 1, value: '未开通' },
    { key: 2, value: '开发中' },
]

// 网关属性
export const networkModeId = [
    { key: 1, value: '灵智网关' },
    { key: 2, value: '第三方MQTT或NB网关' },
]
// 服务器类型
export const serverType = [
    { key: 0, value: '灵智网关' },
    { key: 1, value: '第三方' },
]

// 触发条件类型
export const vType = [
    {
        key: 1,
        A: {
            isShow: true, isConstant: true
        },
        B: {
            isShow: false
        }
    },
    {
        key: 2,
        A: {
            isShow: true, isConstant: false
        },
        B: {
            isShow: false
        }

    },
    {
        key: 3,
        A: {
            isShow: true, isConstant: true
        },
        B: {
            isShow: true, isConstant: true
        }
    },
    {
        key: 4,
        A: {
            isShow: true, isConstant: false
        },
        B: {
            isShow: true, isConstant: false
        }
    },
    {
        key: 5,
        A: {
            isShow: true, isConstant: true
        },
        B: {
            isShow: true, isConstant: false
        }
    },
    {
        key: 6,
        A: {
            isShow: true, isConstant: false
        },
        B: {
            isShow: true, isConstant: true
        }
    },
]

// 序列号状态
export const gatewayState = {
    0: '在线',
    1: '离线',
    2: '告警',
    3: '故障',
}

// 网关绑定状态
export const bindingState = {
    0: '绑定',
    1: '解绑',
}

export const templateData = [
    {
        id: 0,
        templateName: '标题',
        titleText: "灵智物联网平台",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/1.png',
        paramNameObj: {
            param1: "灵智物联网平台"
        }
    },
    {
        id: 1,
        templateName: '运行情况',
        titleText: "运行情况",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/2.png',
        paramNameObj: {
            param1: "总量",
            param2: "在线",
            param3: "离线",
        }
    },
    {
        id: 2,
        templateName: '实时监控',
        titleText: "实时监控",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/3.png',
        paramNameObj: {
            param1: "画面监控"
        }
    },
    {
        id: 3,
        templateName: '当月统计',
        titleText: "当月统计",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/4.png',
        paramNameObj: {
            param1: "当月统计"
        },

    },
    {
        id: 4,
        templateName: '设备列表',
        titleText: "设备列表",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/5.png',
        paramNameObj: {
            param1: "设备列表"
        },
    },
    {
        id: 5,
        templateName: '设备详情',
        titleText: "设备详情",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/6.png',
        paramNameObj: {
            param1: "设备详情",
            param2: "设备名称",
            param3: "所属产品",
            param4: "入网时间",
            param5: "最近修改时间",
            param6: "已绑网关",
        },
    },
    {
        id: 6,
        templateName: '公告',
        titleText: "公告",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/7.png',
        paramNameObj: {
            param1: "公告",

        },
    },
    {
        id: 7,
        templateName: '告警列表',
        titleText: "告警列表",
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        img: 'https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/a/8.png',
        paramNameObj: {
            param1: "告警列表",
            param2: "所属项目",
            param3: "开始时间",
            param4: "结束时间",
            param5: "告警内容",
            param6: "当前状态",

        },
    }
];

export const incrementColor = ['#e592ff', '#9f02a4', '#9aff7f', '#0097d9', '#0258a8', '#00e8e4']

export const localStorageClear = function () {
    return true
}

export const seriousAlarm = [
    {
        id: 1,
        name:"正常告警"
    },
    {
        id: 2,
        name:"久未处理告警"
    },
]

export const predefineColors = [
    '#ff4500',
    '#ff8c00',
    '#ffd700',
    '#90ee90',
    '#00ced1',
    '#1e90ff',
    '#c71585',
    "#91D7F1"
]

export default {
    workTypeBtn,
    baudrate,
    parity,
    dataBits,
    stopBits,
    PLATFORM_IMG,
    wechatimg_IMG,
    ORIGINAL_IMG,
    PLAN_IMG,
    PROJECT_IMG,
    SYSTEM_IMG,
    ALARM_IMG,
    GATEWAY_IMG,
    INSPECTION_IMG,
    SOLUTION_IMG,
    NOTICE_IMG,
    CONFIGURATION_IMG,
    OSS_URL,
    menuState,
    networkModeId,
    vType,
    gatewayState,
    bindingState,
    templateData,
    incrementColor,
    localStorageClear,
    seriousAlarm,
    predefineColors
}
