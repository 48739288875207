<template>
  <el-container style="height: 100%; width: 100%">
     <input type="file" id="files" ref="files" @change="parameterImport" style="display:none">
    <el-header style="width: 100%; height: 100px; padding: 10px">
      <el-image
        style="width: 100%; height: 100px"
        src="https://oss.cniot.fun/sysProgram/head-logo.jpg"
      ></el-image>
    </el-header>
    <el-main class="public-scroll-transparent public-page-style iot-or-main">
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="公共设备" name="0">
          <div class="iot-type-items">
            <el-form :inline="true" style="height: 45px">
              <el-form-item label="行业：">
                <el-select
                  v-model="getOriginalForm.industryId"
                  multiple
                  clearable
                  filterable
                  size="mini"
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in industryData"
                    :key="item.industryId"
                    :label="item.industryName"
                    :value="item.industryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click.stop="selectData"
                  >查询</el-button
                >
              </el-form-item>
              
              <el-form-item class="newaddOr">
                <el-button
                  class="original-create-btn"
                  type="primary"
                  plain
                  size="mini"
                  v-show="
                    $store.state.permissionArr.indexOf('publicOriginal:add') >
                    -1
                  "
                  @click.stop="addOr"
                  >新建设备</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="iot-original-body">
            <div class="iot-original-area">
            <div
              class="iot-original-item"
              v-for="item in orData"
              :key="item.originalId"
            >
              <div class="iot-original-item-image">
                <el-image
                  :src="
                    constant.OSS_URL + constant.ORIGINAL_IMG + item.originalImg
                  "
                ></el-image>
              </div>
              <div class="iot-original-item-words">
                <h4>{{ item.originalName }}</h4>
                <div class="iot-original-item-links">
                  <el-link
                    type="success"
                    :underline="false"
                    @click="showIntroduce(item.originalId, item.originalName)"
                    >设备介绍</el-link
                  >
                  <el-link
                    type="primary"
                    :underline="false"
                    @click.stop="settingPublicParam(item.originalId)"
                    >添加参数</el-link
                  >

                  <div>
                    <el-button
                      type="primary"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf(
                          'publicOriginal:update'
                        ) > -1
                      "
                      @click.stop="editOr(item.originalId)"
                      >修改</el-button
                    >
                    &nbsp;
                    <el-button
                      type="danger"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf(
                          'publicOriginal:delete'
                        ) > -1
                      "
                      @click.stop="delOr(item.originalId)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
          <el-pagination
            @size-change="originalSizeChange"
            @current-change="originalCurrentChange"
            :current-page="getOriginalForm.current"
            :page-sizes="[12, 24, 36, 48]"
            :page-size="getOriginalForm.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="getOriginalForm.originalTotal"
            style="text-align: right; margin-top: 10px; margin-right: 10px"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="私有设备" name="1">
          <div class="iot-type-items">
            <el-form :inline="true" style="height: 45px">
              <el-form-item label="行业：">
                <el-select
                  v-model="getOriginalForm.industryId"
                  multiple
                  clearable
                  filterable
                  size="mini"
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in industryData"
                    :key="item.industryId"
                    :label="item.industryName"
                    :value="item.industryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click.stop="selectData"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item class="newaddOr">
                <el-button
                  class="original-create-btn"
                  type="primary"
                  plain
                  size="mini"
                  v-show="
                    $store.state.permissionArr.indexOf('original:add') > -1
                  "
                  @click.stop="addOr"
                  >新建设备</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="iot-original-body">
          <div class="iot-original-area">
            <div
              class="iot-original-item"
              v-for="item in orData"
              :key="item.originalId"
            >
              <!-- @click="showIntroduce(item.originalId, item.originalName)" -->
              <div class="iot-original-item-image">
                <el-image
                  :src="
                    constant.OSS_URL + constant.ORIGINAL_IMG + item.originalImg
                  "
                ></el-image>
              </div>
              <div class="iot-original-item-words">
                <h4>{{ item.originalName }}</h4>
                <div class="iot-original-item-links">
                  <!-- <el-link
                    type="success"
                    :underline="false"
                    @click.stop="setOrImage(item.originalId)"
                    >设备介绍</el-link
                  > -->
                  <el-link
                    type="primary"
                    :underline="false"
                    @click.stop="settingParam(item.originalId)"
                    >添加参数</el-link
                  >
                  <div>
                    <el-button
                      type="primary"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf('original:update') >
                        -1
                      "
                      @click.stop="editOr(item.originalId)"
                      >修改</el-button
                    >
                    &nbsp;
                    <el-button
                      type="danger"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf('original:delete') >
                        -1
                      "
                      @click.stop="delOr(item.originalId)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
             </div>
          <el-pagination
            @size-change="originalSizeChange"
            @current-change="originalCurrentChange"
            :current-page="getOriginalForm.current"
            :page-sizes="[12, 24, 36, 48]"
            :page-size="getOriginalForm.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="getOriginalForm.originalTotal"
            style="text-align: right; margin-top: 10px; margin-right: 10px"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <!-- 新增设备表单 -->
    <el-dialog
    top="10%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="dialogVisibile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        ref="originalForm"
        :model="orForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="所属行业" prop="industryId">
          <el-radio
            size="mini"
            v-for="item in industryData"
            :key="item.industryId"
            v-model="orForm.industryId"
            :label="item.industryId"
            border
            >{{ item.industryName }}</el-radio
          >
        </el-form-item>
        <el-form-item label="设备名称" prop="originalName">
          <el-input v-model="orForm.originalName" style="width: 70%"></el-input>
        </el-form-item>
        <!-- <el-form-item label="设备说明" prop="remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入设备说明"
            style="width: 70%"
            v-model="orForm.remark"
          >
          </el-input>
        </el-form-item> -->
        <el-form-item label="设备图片" prop="originalImg">
          <el-form ref="imgUpload">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :action="orImgUpload"
              :data="{
                typeName: 'original',
              }"
              :multiple="false"
            >
              <img
                class="avatar"
                v-if="orForm.originalImg"
                :src="
                  constant.OSS_URL + constant.ORIGINAL_IMG + orForm.originalImg
                "
              />
              <img
                class="avatar"
                v-else-if="imgUrl"
                :src="constant.OSS_URL + constant.ORIGINAL_IMG + imgUrl"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div><span style="color:red;">*</span>点击修改图片</div>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="submitForm()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="dialogVisibile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
    <!-- 新增公共基础信息表单 -->
    <el-dialog
    top="8%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="publicConfigVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      width="70%"
      class="iot-dialog"
    >
      <el-form
        ref="paramRef"
        :model="serialPortForm"
        label-width="120px"
        :rules="rulesConfig"
        label-position="left"
      >
        <el-form-item label="通讯协议" prop="protocolId">
          <el-select v-model="serialPortForm.protocolId" placeholder="请选择">
            <el-option
              v-for="item in cpData"
              :key="item.protocolId"
              :label="item.protocolName"
              :value="item.protocolId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="从机地址" prop="slaveAddr">
          <el-input v-model="serialPortForm.slaveAddr"></el-input>
        </el-form-item>
        <el-form-item label="波特率" prop="baudrate">
          <el-select v-model="serialPortForm.baudrate" placeholder="请选择">
            <el-option
              v-for="item in constant.baudrate"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据位" prop="dataBits">
          <el-radio
            v-model="serialPortForm.dataBits"
            v-for="item in constant.dataBits"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item label="校验位" prop="parity">
          <el-radio
            v-model="serialPortForm.parity"
            v-for="item in constant.parity"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item label="停止位" prop="stopBits">
          <el-radio
            v-model="serialPortForm.stopBits"
            v-for="item in constant.stopBits"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="paramPage"
        stripe
        border=""
        :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      >
        <el-table-column prop="paramName" label="参数名称" width="180"></el-table-column>
        <el-table-column prop="paramTag" label="英文识别符" width="180"></el-table-column>
        <el-table-column prop="functionCode" label="功能码"></el-table-column>
        <el-table-column
          prop="paramTypeName"
          label="参数类型"
        >
         <template slot-scope="scope">
          <div v-if="scope.row.paramTypeId==1">数值</div>
          <div v-else-if="scope.row.paramTypeId==2">布尔</div>
          <div v-else-if="scope.row.paramTypeId==3">整数型浮点</div>
          <div v-else>浮点</div>
        </template>
        </el-table-column>
        <el-table-column
        width="120"
          prop="typeValue"
          label="保留小数位"
        ></el-table-column>

        <el-table-column prop="cmdMaxValue" width="100" label="参数上限"></el-table-column>
        <el-table-column prop="cmdMinValue" width="110" label="参数下限"></el-table-column>
        <el-table-column prop="dataAddr" label="数据地址"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column prop="dataAttr" label="数据属性"> </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              v-show="
                $store.state.permissionArr.indexOf('publicParameter:update') >
                -1 
              "
              @click="editParam(scope.row.paramId)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              plain
              v-show="
                $store.state.permissionArr.indexOf('publicParameter:delete') >
                -1
              "
              @click="delParam(scope.row.paramId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paramSizeChange"
        @current-change="paramCurrentChange"
        :current-page="paramPage.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="paramPage.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="getParamForm.paramTotal"
        style="text-align: right; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
     
      <div slot="footer" class="dialog-footer">
         <el-button
        size="small"
        type="primary"
        plain
        class="el-icon-plus iot-create-btn"
        v-show="$store.state.permissionArr.indexOf('parameter:import') > -1&&$store.state.userId==1"
        @click="file()"
        >参数导入</el-button
      >
         <el-button
        size="small"
        type="primary"
        plain
      
        v-show="$store.state.permissionArr.indexOf('parameter:export') > -1&&$store.state.userId==1"
        @click="parameterExport()"
        >参数导出</el-button
      >
         <el-button
        size="small"
        type="primary"
        plain
       
        v-show="$store.state.permissionArr.indexOf('publicParameter:add') > -1"
        @click="addParam()"
        >新增参数</el-button
      >
        <el-button
          type="primary"
          plain
          size="mini"
          v-show="
            $store.state.permissionArr.indexOf(
              'publicSerialPortConfig:update'
            ) > -1
          "
          @click="submeitOr()"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="publicConfigVisinbile = false"
          >取 消</el-button
        >
        
      </div>
      <!-- 新增参数表单 -->
      <el-dialog
      top="8%"
        class="iot-dialog"
        :title="formTitle"
        :visible.sync="childVisible"
        :before-close="del"
        append-to-body
      >
        <el-form
          ref="childRef"
          :model="paramForm"
          label-width="120px"
          :rules="rulesParam"
          label-position="left"
        >
          <el-form-item label="参数名称" prop="paramName">
            <el-input
              v-model="paramForm.paramName"
              placeholder="请输入参数名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文识别符" prop="paramTag">
            <el-input
              v-model="paramForm.paramTag"
              placeholder="请输入英文识别符"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!isCreateParam" label="排序" prop="orderNum">
            <el-input-number
              :controls="false"
              v-model="paramForm.orderNum"
              :min="1"
              placeholder="请输入排序号"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="参数类型" prop="paramTypeId">
            <el-select v-model="paramForm.paramTypeId" placeholder="请选择">
              <el-option
                v-for="item in paramTypeArr"
                :key="item.paramTypeId"
                :label="item.paramTypeName"
                :value="item.paramTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="paramForm.paramTypeId == 3||paramForm.paramTypeId == 4"
            label="保留小数位数"
            prop="typeValue"
          >
            <el-input-number
              v-model="paramForm.typeValue"
              :min="1"
              :max="3"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="数据地址" prop="dataAddr">
            <el-input-number
              :controls="false"
              v-model="paramForm.dataAddr"
              :min="0"
              :max="65535"
              placeholder="请输入数据地址"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="数据属性" prop="dataAttr">
            <el-select v-model="paramForm.dataAttr" placeholder="请选择">
              <el-option
                v-for="item in constant.dataAttr"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content=""
              placement="right-start"
            >
              <div slot="content" style="font-size: 14px; line-height: 26px">
              Signed<br />单字有符号
              <br />Unsiged<br />单字无符号
              <br />32_Signed_le<br />双字有符号整数，小端
              <br />32_Signed_le_swap<br />双字有符号整数，小端交换
              <br />32_Signed_be<br />双字有符号整数，大端
              <br />32_Signed_be_swap<br />双字有符号整数，大端交换
              <br />32_Float_Le<br />双字有符号浮点，小端
              <br />32_Float_Le_swap<br />双字有符号浮点，小端交换
              <br />32_Float_Be<br />双字有符号浮点，大端
              <br />32_Float_Be_swap<br />双字有符号浮点，大端交换
              </div>
              <i class="el-icon-question" style="font-size: 20px"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="下发最大值" prop="cmdMaxValue">
            <el-input-number
              :controls="false"
              v-model="paramForm.cmdMaxValue"
              :min="paramForm.cmdMinValue"
              placeholder="默认值：999999999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="下发最小值" prop="cmdMinValue">
            <el-input-number
              :controls="false"
              v-model="paramForm.cmdMinValue"
              :max="paramForm.cmdMaxValue"
              placeholder="默认值：-999999999"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="submeitParam()"
            >确 定</el-button
          >
          <el-button type="info" plain size="mini" @click="childVisible = false"
            >取 消</el-button
          >
          
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 新增私有基础信息表单 -->
    <el-dialog
    top="8%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="privateConfigVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      class="iot-dialog"
      width="70%"
    >
      <el-form
        ref="paramRef"
        :model="serialPortForm"
        label-width="120px"
        :rules="rulesConfig"
        label-position="left"
      >
        <el-form-item label="通讯协议" prop="protocolId">
          <el-select v-model="serialPortForm.protocolId" placeholder="请选择">
            <el-option
              v-for="item in cpData"
              :key="item.protocolId"
              :label="item.protocolName"
              :value="item.protocolId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="从机地址" prop="slaveAddr">
          <el-input v-model="serialPortForm.slaveAddr"></el-input>
        </el-form-item>
        <el-form-item label="波特率" prop="baudrate">
          <el-select v-model="serialPortForm.baudrate" placeholder="请选择">
            <el-option
              v-for="item in constant.baudrate"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据位" prop="dataBits">
          <el-radio
            v-model="serialPortForm.dataBits"
            v-for="item in constant.dataBits"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item label="校验位" prop="parity">
          <el-radio
            v-model="serialPortForm.parity"
            v-for="item in constant.parity"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item label="停止位" prop="stopBits">
          <el-radio
            v-model="serialPortForm.stopBits"
            v-for="item in constant.stopBits"
            :key="item.key"
            :label="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="paramPage"
        stripe
        border=""
        :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      >
        <el-table-column prop="paramName" label="参数名称" width="180"></el-table-column>
        <el-table-column prop="paramTag" label="英文识别符" width="180"></el-table-column>
        <el-table-column prop="functionCode" label="功能码"></el-table-column>
        <el-table-column
          
          label="参数类型"
        >
        <template slot-scope="scope">
          <div v-if="scope.row.paramTypeId==1">数值</div>
          <div v-else-if="scope.row.paramTypeId==2">布尔</div>
          <div v-else-if="scope.row.paramTypeId==3">整数型浮点</div>
          <div v-else>浮点</div>
        </template>
        </el-table-column>
       
        <el-table-column
        width="120"
          prop="typeValue"
          label="保留小数位数"
        ></el-table-column>
        <el-table-column prop="cmdMaxValue" width="120" label="参数上限"></el-table-column>
        <el-table-column prop="cmdMinValue" width="120" label="参数下限"></el-table-column>
        <el-table-column prop="dataAddr" label="数据地址"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column prop="dataAttr" label="数据属性"></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              v-show="
                $store.state.permissionArr.indexOf('parameter:update') > -1
              "
              @click="editParam(scope.row.paramId)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              plain
              v-show="
                $store.state.permissionArr.indexOf('parameter:delete') > -1
              "
              @click="delParam(scope.row.paramId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      
      <el-pagination
        @size-change="paramSizeChange"
        @current-change="paramCurrentChange"
        :current-page="paramPage.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="paramPage.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="getParamForm.paramTotal"
        style="text-align: right; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
      <div slot="footer" class="dialog-footer">
       
        <el-button
        size="small"
        type="primary"
        plain
        class="el-icon-plus iot-create-btn"
        v-show="$store.state.permissionArr.indexOf('parameter:import') > -1"
        @click="file()"
        >参数导入</el-button
      >
         <el-button
        size="small"
        type="primary"
        plain
        
        v-show="$store.state.permissionArr.indexOf('parameter:export') > -1"
        @click="parameterExport()"
        >参数导出</el-button
      >
        <el-button
          size="mini"
          type="primary"
          plain
       
          v-show="$store.state.permissionArr.indexOf('parameter:add') > -1"
          @click="addParam()"
          >新增参数</el-button
        >
        <el-button type="primary" plain size="mini" @click="submeitOr()"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="privateConfigVisinbile = false"
          >取 消</el-button
        >
        
      </div>
      <!-- 新增参数表单 -->
      <el-dialog
      top="8%"
        class="iot-dialog"
        :title="formTitle"
        :visible.sync="childVisible"
        :before-close="del"
        append-to-body
      >
        <el-form
          ref="childRef"
          :model="paramForm"
          label-width="120px"
          :rules="rulesParam"
          label-position="left"
        >
          <el-form-item label="参数名称" prop="paramName">
            <el-input
              v-model="paramForm.paramName"
              placeholder="请输入参数名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文识别符" prop="paramTag">
            <el-input
              v-model="paramForm.paramTag"
              placeholder="请输入英文识别符"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能码" prop="functionCode">
            <el-radio-group v-model="paramForm.functionCode">
              <el-radio :label="1">1</el-radio>
              <el-radio :label="2">2</el-radio>
              <el-radio :label="3">3</el-radio>
              <el-radio :label="4">4</el-radio>
            </el-radio-group>
            <el-tooltip
              style="margin-left: 40px"
              class="item"
              effect="dark"
              content=""
              placement="right-start"
            >
              <div slot="content" style="font-size: 14px; line-height: 26px">
                1（读取线圈状态）<br />取得一组线圈的当前状态（ON/OFF）；<br />
                2（读取输入状态）<br />取得一组开关输入的当前状态（ON/OFF）；<br />
                3（读取保持寄存器）<br />在一个或多个保持寄存器中的值；<br />
                4（读取输入寄存器）<br />在一个或多个输入寄存器中的值；<br />
              </div>
              <i class="el-icon-question" style="font-size: 20px"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item v-if="!isCreateParam" label="排序" prop="orderNum">
            <el-input-number
              :controls="false"
              v-model="paramForm.orderNum"
              :min="1"
              placeholder="排序号"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="参数类型" prop="paramTypeId">
            <el-select v-model="paramForm.paramTypeId">
              <el-option
                v-for="item in paramTypeArr"
                :key="item.paramTypeId"
                :label="item.paramTypeName"
                :value="item.paramTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="paramForm.paramTypeId == 3||paramForm.paramTypeId == 4"
            label="保留小数位数"
            prop="typeValue"
          >
            <el-input-number
              v-model="paramForm.typeValue"
              :min="1"
              :max="3"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="数据地址" prop="dataAddr">
            <el-input-number
              :controls="false"
              v-model="paramForm.dataAddr"
              :min="0"
              :max="65535"
              placeholder="请输入数据地址"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="数据属性" prop="dataAttr">
            <el-select v-model="paramForm.dataAttr" placeholder="请选择">
              <el-option
                v-for="item in constant.dataAttr"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
            <el-tooltip
              style="margin-left: 10px"
              class="item"
              effect="dark"
              content=""
              placement="right-start"
            >
              <div slot="content" style="font-size: 14px; line-height: 26px">
                Signed<br />单字有符号<br />Unsiged<br />单字无符号<br />32_Signed_le<br />双字有符号整数，小端<br />32_Signed_le_swap<br />双字有符号整数，小端交换<br />32_Signed_be<br />
                双字有符号整数，大端<br />32_Signed_be_swap<br />双字有符号整数，大端交换
                <br />32_Float_Le<br />双字有符号浮点，小端
              <br />32_Float_Le_swap<br />双字有符号浮点，小端交换
              <br />32_Float_Be<br />双字有符号浮点，大端
              <br />32_Float_Be_swap<br />双字有符号浮点，大端交换
              </div>
              <i class="el-icon-question" style="font-size: 20px"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="下发最大值" prop="cmdMaxValue">
            <el-input-number
              :controls="false"
              v-model="paramForm.cmdMaxValue"
              :min="paramForm.cmdMinValue"
              placeholder="默认值：999999999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="下发最小值" prop="cmdMinValue">
            <el-input-number
              :controls="false"
              v-model="paramForm.cmdMinValue"
              :max="paramForm.cmdMaxValue"
              placeholder="默认值：-999999999"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="submeitParam()"
            >确 定</el-button
          >
          <el-button type="info" plain size="mini" @click="childVisible = false"
            >取 消</el-button
          >
          
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog

      class="iot-show-intro"
      title="设备介绍"
      :show-close="false"
      :visible.sync="orImageVisibile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      :width="40 + '%'"
      top="3%"
    >
      <!-- 说明书 -->
      <el-upload
        ref="orImage"
        class="upload-demo"
        :action="orImgUpload"
        list-type="picture"
        :file-list="imageArr"
        :with-credentials="true"
        :on-remove="deleteImage"
        :data="{
          typeName: 'original',
        }"
        :on-success="imgChange"
        :before-upload="beforeAvatarUpload1"
      >
        <el-tooltip
          class="item"
          effect="dark"
          content="只能上传jpg/png文件，且不超过500kb"
          placement="right-start"
        >
          <el-button size="small" type="primary">添加图片</el-button>
        </el-tooltip>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickUpload()" size="small" type="primary"
          >点击上传</el-button
        >
        <el-button
          @click="orImageVisibile = false"
          size="small"
          type="default"
          plain
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- :title="orIntroduceTitle" -->
    <el-dialog
      :visible.sync="orIntroduceVisibile"
      class="iot-show-intro"
      :lock-scroll="true"
      width="775px"
      top="3%"
      style="overflow: hidden"
    >
      <div slot="title">
        {{ orIntroduceTitle }}
        <el-button
          size="mini"
          style="float: right; margin-right: 20px; margin-top: -5px"
          @click.stop="setOrImage(selectId)"
          v-show="$store.state.permissionArr.indexOf('publicOriginal:add') > -1"
          type="primary"
          plain
          >编辑介绍</el-button
        >
        <!-- <el-link
          style="float: right; margin-right: 20px"
          type="success"
          :underline="false"
          @click.stop="setOrImage(selectId)"
          v-show="$store.state.permissionArr.indexOf('publicOriginal:add') > -1"
          >编辑设备介绍</el-link
        > -->
      </div>
      <div
        class="iot-intro-scroll"
        style="width: 770px; height: 800px; overflow-y: auto"
      >
        <el-image
          style="width: 750px"
          lazy
          v-for="(item, index) in imageArr"
          :key="index"
          :src="item.url"
        ></el-image>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import { baseUrl } from "../util/global";
import {
  baudrate,
  parity,
  dataBits,
  stopBits,
  dataAttr,
  ORIGINAL_IMG,
  OSS_URL,
} from "../util/constant";
import { TABLE_HEADER_COLOR } from "../util/theme";
import reg from "../util/regular";

let that;
export default {
  name: "OriginalScript",
  data() {
    return {
      orIntroduceTitle: "设备介绍",
      orIntroduceVisibile: false,
      isPublic: "0",
      imageArr: [],
      imgData: {
        imgNames: [],
        originalId: "",
      },
      orImageVisibile: false,
      theme: { TABLE_HEADER_COLOR },
      orImgUpload: baseUrl + "img",
      imgUrl: "original.png",
      constant: {
        baudrate,
        parity,
        dataBits,
        stopBits,
        dataAttr,
        ORIGINAL_IMG,
        OSS_URL,
      },
      activeTab: "0",
      getOriginalForm: {
        industryId: "",
        size: 12,
        current: 1,
        originalTotal: 0,
      },
      industryData: [],
      cpData: [],
      orData: [],
      dialogVisibile: false,
      publicConfigVisinbile: false,
      privateConfigVisinbile: false,
      childVisible: false,
      isCreate: true,
      formTitle: "",
      isCreateParam: true,
      orForm: {
        originalName: "",
        originalImg: "original.png",
        remark: "",
        industryId: "",
        originalId: "",
      },
      serialPortForm: {
        originalId: "",
        industryId: "",
        protocolId: "",
        slaveAddr: 10,
        baudrate: 115200,
        dataBits: 8,
        stopBits: 1,
        parity: "None",
      },
      rules: {
        originalName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
          {
            pattern: reg.originalName,
            message: "设备名称不能为纯数字！",
          },
        ],
      },
      rulesConfig: {
        protocolId: [
          { required: true, message: "请选择通讯协议", trigger: "blur" },
        ],
        slaveAddr: [
          { required: true, message: "请输入从机地址", trigger: "blur" },
        ],
        baudrate: [
          { required: true, message: "请选择波特率", trigger: "blur" },
        ],
        parity: [{ required: true, message: "请选择校验位", trigger: "blur" }],
      },
      rulesParam: {
        paramName: [
          { required: true, message: "请输入参数名称", trigger: "blur" },
          {
            pattern: reg.paramName,
            message: "请输入1-8个字符",
          },
        ],
        paramTag: [
          { required: true, message: "请输入参数标识", trigger: "blur" },
          {
            pattern: reg.parmaP,
            message: "支持30个字符",
          },
          {
            pattern: reg.parmaStr,
            message: "参数标识仅支持字母和数字",
          },
          {
            pattern: reg.parmaStrNum,
            message: "参数标识不能为纯数字构成",
          },
        ],
        functionCode: [
          { required: true, message: "请输入功能码", trigger: "blur" },
        ],
        paramTypeId: [
          { required: true, message: "请选择参数类型", trigger: "blur" },
        ],
        dataAddr: [
          { required: true, message: "请输入数据地址", trigger: "blur" },
        ],
        dataAttr: [
          { required: true, message: "请选择数据属性", trigger: "blur" },
        ],
      },
      getParamForm: {
        originalId: "",
        current: 1,
        size: 10,
        paramTotal: 0,
      },
      paramPage: [],
      paramForm: {
        paramId: "",
        paramName: "",
        paramTag: "",
        originalId: "",
        orderNum: "",
        paramAddr: "",
        paramTypeId: "",
        remark: "",
        typeValue: 0,
        functionCode: 3,
      },
      paramTypeArr: [],
      dialogVisible: false,
      windowWidth: "",
      selectId: "",
    };
  },
  methods: {
    del(done){
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      
    },
    file(){
      this.$refs.files.click();
    },
    // 参数导入
    parameterImport(e){
      const file = e.target.files[0];   
      let form=new FormData();
      form.append("file",file);
      form.append("originalId",that.paramForm.originalId);
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });
      that.$api.parameter.putParameter(form).then(res=>{
        loading.close();
        if (res.code==200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          if (that.publicConfigVisinbile) {
            that.settingPublicParam(that.paramForm.originalId)
          }else if(that.privateConfigVisinbile){
            that.settingParam(that.paramForm.originalId)
          }
        }
        
      });
      document.getElementById("files").value="";

    },
    // 参数导出
    parameterExport(){
         const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      that.$api.original.getParameter(that.paramForm.originalId).then(res=>{
           loading.close();
        if (res.code==200) {
          window.open(res.data)
        }
      })
    },



    showIntroduce: function (id, orName) {
      that.selectId = id;
      that.$api.original.checkPic(id).then((response) => {
        if (response.code == 200) {
          let arr = [];
          response.data.forEach((element) => {
            arr.push({
              name: element,
              url: that.constant.OSS_URL + that.constant.ORIGINAL_IMG + element,
            });
          });
          that.imageArr = arr;
          that.$set(that.imgData, "imgNames", response.data);
          that.$set(that.imgData, "originalId", id);
          that.orIntroduceTitle = orName + "介绍";
          that.orIntroduceVisibile = true;
        }
      });
    },
    beforeAvatarUpload1(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    imgChange: function (response) {
      if (response.code == 200) {
        let arr = that.imgData.imgNames;
        arr.push(response.data);
        that.$set(that.imgData, "imgNames", arr);
      }
    },
    clickUpload: function () {
      that.$api.original.addPic(that.imgData).then((response) => {
        if (response.code == 200) {
          that.$message({
            type: "success",
            message: "上传成功",
          });
          that.orImageVisibile = false;
          that.imgData = {
            imgNames: [],
            originalId: "",
          };
        }
      });
    },
    deleteImage: function (file) {
      that.$api.original.deletePic(file.name).then((response) => {
        if (response.code == 200) {
          let arr = that.imgData.imgNames;
          arr.forEach((element, idx) => {
            if (element == file.name) {
              if (arr.length <= 1) {
                arr = [];
              } else {
                delete arr[idx];
              }
            }
          });

          that.$set(that.imgData, "imgNames", arr);
          that.clickUpload();
        }
      });
    },
    setOrImage: function (id) {
      that.$api.original.checkPic(id).then((response) => {
        if (response.code == 200) {
          let arr = [];
          response.data.forEach((element) => {
            arr.push({
              name: element,
              url: that.constant.OSS_URL + that.constant.ORIGINAL_IMG + element,
            });
          });
          that.imageArr = arr;
          that.$set(that.imgData, "imgNames", response.data);
          that.$set(that.imgData, "originalId", id);
          that.orImageVisibile = true;
        }
      });
    },
    getType(resolve) {
      that.$api.original.getIndustryList().then((response) => {
        if (response.code == 200) {
          that.industryData = response.data;
          return resolve();
        }
      });
    },
    //通讯协议
    getProtocolList() {
      that.$api.communicationProtocol.getProtocolList().then((response) => {
        if (response.code == 200) {
          that.cpData = response.data;
        }
      });
    },
    getParamTypeArr() {
      that.$api.parameter.getParamType().then((response) => {
        if (response.code == 200) {
          that.paramTypeArr = response.data;
          if (that.isCreateParam == true) {
            that.$set(
              that.paramForm,
              "paramTypeId",
              that.paramTypeArr[0].paramTypeId
            );
            that.$set(
              that.paramForm,
              "dataAttr",
              that.constant.dataAttr[0].key
            );
          }
        }
      });
    },
    handleClick(d) {
      that.isPublic = d.name;
      that.$set(that.getOriginalForm, "current", 1);
      that.getOriginalPage(that.getOriginalForm);
    },
    originalSizeChange(val) {
      that.$set(that.getOriginalForm, "size", val);
      that.$set(that.getOriginalForm, "current", 1);
      that.getOriginalPage(that.getOriginalForm);
    },
    originalCurrentChange(val) {
      that.$set(that.getOriginalForm, "current", val);
      that.getOriginalPage(that.getOriginalForm);
    },
    paramSizeChange(val) {
      that.$set(that.getParamForm, "size", val);
      that.$set(that.getParamForm, "current", 1);
      that.getParamPage(that.getParamForm);
    },
    paramCurrentChange(val) {
      that.$set(that.getParamForm, "current", val);
      that.getParamPage(that.getParamForm);
    },
    selectData() {
      that.$set(that.getOriginalForm, "current", 1);
      that.getOriginalPage(that.getOriginalForm);
    },
    getOriginalPage(data) {
      data["isPublic"] = that.isPublic;

      that.$api.original.getOrPage(data).then((response) => {
        if (response.code == 200) {
          that.orData = response.data.records;
          that.getOriginalForm.originalTotal = response.data.total;
        }
      });
    },
    //参数列表分页
    getParamPage() {
      that.$api.parameter.getParamPage(that.getParamForm).then((response) => {
        if (response.code == 200) {
          that.paramPage = response.data.records;
          that.getParamForm.paramTotal = response.data.total;
        }
      });
    },
    handleAvatarSuccess(res) {
      if (200 == res.code) {
        that.imgUrl = res.data;
        that.$set(that.orForm, "originalImg", that.imgUrl);
      }
    },
    beforeAvatarUpload(file) {
      that.$refs["imgUpload"].validate((valid) => {
        if (valid) {
          const isImg =
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
          if (!isImg) {
            this.$message.error("图片格式仅限jpeg，jpg，png，gif!");
          }
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error("图片大小不得超过2MB!");
          }
          return isImg && isLt2M;
        } else {
          this.$message.error("请查看表单信息是否有遗漏！");
        }
      });
    },
    addOr() {
      that.orForm.originalImg="original.png";
      that.imgUrl="original.png";
      that.dialogVisibile = true;
      that.isCreate = true;
      that.formTitle = "新增设备";
      that.orForm = {};
    },
    editOr(id) {
      that.$api.original.getOriginal(id).then((response) => {
        if (response.code == 200) {
          that.orForm = response.data;
          that.dialogVisibile = true;
          that.isCreate = false;
          that.formTitle = "修改设备";
        }
      });
    },
    delOr(oid) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
            const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
          let _api =
            Number(that.isPublic) == 0
              ? that.$api.original.delPublicOr(oid)
              : that.$api.original.delPrivateOr(oid);
          _api.then((response) => {
            loading.close();
            if (response.code == 200) {
              
              that.$message({
                type: "success",
                message: "提交成功",
              });
              that.getOriginalPage(that.getOriginalForm);
            }
          });
        });
    },
    submitForm() {
      // if(that.orForm.originalImg !=undefined){
        if (!that.orForm.originalImg) {
          that.orForm.originalImg="original.png" 
        }
   
         that.$refs["originalForm"].validate((valid) => {
        if (valid) {
          if (that.isCreate) {
            let _api =
              Number(that.isPublic) == 0
                ? that.$api.original.addPublicOr(that.orForm)
                : that.$api.original.addPrivateOr(that.orForm);
            _api.then((response) => {
              if (response.code == 200) {
                that.$message({
                  type: "success",
                  message: "提交成功",
                });
                that.imgUrl="original.png"
                that.isCreate = true;
                that.dialogVisibile = false;
                that.getOriginalPage(that.getOriginalForm);
              }else{
                 that.$message({
                  type: "error",
                  message: response.data,
                });
              }
            });
          } else {
            let _api =
              Number(that.isPublic) == 0
                ? that.$api.original.editPublicOr(that.orForm)
                : that.$api.original.editPrivateOr(that.orForm);
            _api.then((response) => {
              if (response.code == 200) {
                that.$message({
                  type: "success",
                  message: "提交成功",
                });
                that.isCreate = true;
                that.dialogVisibile = false;
                that.getOriginalPage(that.getOriginalForm);
              }
            });
          }
        }
      });
      // }else{
      //   that.$message({
      //       type: "error",
      //       message: "请上传设备图片！",
      //     });
      // }
     
    },
    settingPublicParam(originalId) {
      that.formTitle = "公共设备配置";
      that.$api.original.getConfig(originalId).then((response) => {
        if (response.code == 200) {
          that.getProtocolList();
          that.serialPortForm = response.data;
          that.$set(that.getParamForm, "originalId", originalId);
          that.$set(that.paramForm, "originalId", originalId);
          that.publicConfigVisinbile = true;
          that.getParamPage();
        }
      });
    },
    settingParam(originalId) {
      that.formTitle = "私有设备配置";
      that.$api.original.getConfig(originalId).then((response) => {
        if (response.code == 200) {
          that.getProtocolList();
          that.serialPortForm = response.data;
          that.$set(that.getParamForm, "originalId", originalId);
          that.$set(that.paramForm, "originalId", originalId);
          that.privateConfigVisinbile = true;
          that.getParamPage();
        }
      });
    },
    submeitOr() {
      that.$api.original.addConfig(that.serialPortForm).then((response) => {
        if (response.code == 200) {
          that.$refs.paramRef.resetFields();
          that.publicConfigVisinbile = false;
          that.privateConfigVisinbile = false;
          that.getProtocolList();
          that.$message({
            type: "success",
            message: "提交成功",
          });
        }
      });
    },
    addParam() {
      that.childVisible = true;
      that.formTitle = "新增参数";
      that.isCreateParam = true;
      that.getParamTypeArr();
      that.$set(that.paramForm, "originalId", that.paramForm.originalId);
    },
    editParam(id) {
      that.$api.parameter.paramDetail(id).then((response) => {
        if (response.code == 200) {
          that.formTitle = "修改参数";
          that.childVisible = true;
          that.paramForm = response.data;
          that.isCreateParam = false;
          that.getParamTypeArr();
        }
      });
    },
    submeitParam() {
      
      if (that.paramForm.paramTypeId==1) {
        that.paramForm.typeValue=0;
      }
      that.$refs["childRef"].validate((valid) => {
        if (valid) {
          if (that.isCreateParam) {
            let _api =
              Number(that.isPublic) == 0
                ? that.$api.parameter.createPublicParam(that.paramForm)
                : that.$api.parameter.createParam(that.paramForm);
            _api
              .then((response) => {
                if (response.code == 200) {
                  that.$refs.childRef.resetFields();
                  that.childVisible = false;
                  that.isCreateParam = true;
                  that.getParamPage();
                  that.$message({
                    type: "success",
                    message: "提交成功",
                  });
                } else {
                  that.$message({
                    type: "warning",
                    message: response.msg,
                  });
                }
              })
            
          } else {
            let _api =
              Number(that.isPublic) == 0
                ? that.$api.parameter.editPublicParam(that.paramForm)
                : that.$api.parameter.editParam(that.paramForm);
            _api
              .then((response) => {
                if (response.code == 200) {
                  that.$refs.childRef.resetFields();
                  that.childVisible = false;
                  that.isCreateParam = true;
                  that.getParamPage();
                  that.$message({
                    type: "success",
                    message: "提交成功",
                  });
                }
              })
         
          }
        }
      });
    },
    delParam(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let _api =
            Number(that.isPublic) == 0
              ? that.$api.parameter.delPublicParam(id)
              : that.$api.parameter.delParam(id);
          _api.then((response) => {
            if (response.code == 200) {
              that.getParamPage();
              that.$message({
                type: "success",
                message: "删除成功",
              });
            }
          });
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    that = this;
  },
  mounted() {
    that.windowWidth = document.body.clientWidth;
    let pro = new Promise(function (resolve) {
      that.getType(resolve);
    });
    pro.then(() => {
      // let userId = that.$store.state.userId;

      // if (1 == userId) {
      //   that.activeTab = "0";
      //   that.isPublic = "0";
      // } else {
      //   that.activeTab = "1";
      //   that.isPublic = "1";
      // }
      that.getOriginalPage(that.getOriginalForm);
    });
  },
};
</script>
<style scoped>
.iot-or-main {
  position: relative;
  height: calc(100% - 100px);
  margin-top: 20px;
  width: calc(100% - 25px);
  margin: 20px 12px 0px 12px !important;
}
.iot-or-main >>> .el-tabs__item:hover,
.iot-or-main >>> .el-tabs__item:focus,
.iot-or-main >>> .el-tabs__item {
  color: #303133;
}
.iot-type-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
}
.iot-type-items form{
  width: 100%;
}
.newaddOr{
  position: absolute;
  top: 0;
  right: 0;
}
.iot-type-item {
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}
.iot-original-area {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  
}
.iot-original-body{
height: 500px;
overflow-y: auto;
}
.iot-original-body::-webkit-scrollbar {
  width: 10px;
}
.iot-original-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.iot-original-item {
  width: 32%;
  height: 14.5%;
  /* width: 32%;
  height: 120px; */
  margin: 5px;
  border: 1px solid #ebeef5;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
}
.iot-original-item:hover {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.iot-original-item-image {
  display: flex;
  width: 110px;
  height: 110px;
  padding-top: 5px;
  padding-left: 10px;
}
.iot-original-item-image img,
.iot-original-item-image >>> .el-image {
  width: 100px;
  height: 100px;
}
.iot-original-item-words {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-content: space-around;
  margin-left: 10px;
}
.iot-original-item-words h4 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-top: 15px;
}
.iot-original-item-links {
  position: relative;
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 30px;
}
.iot-or-create-btn {
  position: absolute;
  right: 30px;
  z-index: 1;
}
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-child-item >>> .el-form-item {
  margin-bottom: 22px;
}
.iot-dialog >>> .el-dialog__body {
  height: 485px;
  overflow-y: auto;
}
.iot-dialog >>> .el-dialog__body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.iot-dialog >>> .el-dialog__body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

.iot-dialog >>> .el-dialog__body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.original-create-btn {
  margin-top: 7px;
}
.iot-create-btn {
  margin-left: calc(50% - 40px);
  margin-top: 10px;
}
.remark {
  margin: 5px;
  width: 150px;
  font-size: 12px;
  height: 48px;
  overflow: hidden; /* 超出的文本隐藏 */
  text-overflow: ellipsis; /* 溢出用省略号显示 */
  color: #aaa;
}
.avatar-uploader .el-upload {
  border: 5px dashed #a8a8a82e;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 150px;
  height: 150px;
  line-height: 130px;
  text-align: center;
  font-size: 28px;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.iot-show-intro >>> .el-dialog__body {
  padding: 0 10px 5px 10px;
  width: 770px;
  height: 775px;
  overflow-y: auto;
}
.iot-show-intro >>> .el-dialog__body::-webkit-scrollbary::-webkit-scrollbar,
.iot-intro-scroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 1px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.iot-show-intro >>> .el-dialog__body::-webkit-scrollbar-thumb,
.iot-intro-scroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}

.iot-show-intro >>> .el-dialog__body::-webkit-scrollbar-track,
.iot-intro-scroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 1px;
  background: rgba(0, 0, 0, 0);
}
</style>
