const mobileR = /^((13[0-9])|(14[^0-4])|(15[^4])|(16[2,5,6])|(17[0-8])|(18[0-9])|(19[1,8,9]))\d{8}$/;//手机号
const accountR = /^[\\u4e00-\\u9fa5_a-zA-Z0-9-]{6,18}$/; //限6-18个字符，支持中英文、数字、减号或下划线
const pswR = /^[\\u4e00-\\u9fa5_a-zA-Z0-9-]{6,18}$/; //限6-18个字符，支持中英文、数字、减号或下划线
const nameR = /^.{0,15}$/; //限0-15个字符
const fullNameR = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,8}\d{0,4}$/; //限2-6个名字
const parmaP = /^.{0,30}$/; //限0-30个字符
const parmaStr = /^\w{0,30}$/; //限0-30个字符
const parmaStrNum = /\d[A-Za-z]|[A-Za-z]\d|[A-Za-z]/; //限0-30个字符
const paramName = /^.{1,8}$/; //限0-10个字符
const originalName = /[\u4E00-\u9FA5\uf900-\ufa2d_a-zA-Z]{1,}/; //限0-10个字符
export default {
    mobileR,
    accountR,
    pswR,
    nameR,
    fullNameR,
    parmaP,
    parmaStr,
    parmaStrNum,
    paramName,
    originalName
}